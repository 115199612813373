/* .App {
  text-align: center;
}

  @font-face {
    font-family: 'CentraMedium';
    font-style: normal;
    font-weight: 400;
    src: url("./res/fonts/CentraNo2-Medium.ttf");
  }

  @font-face {
    font-family: 'CentraLight';
    font-style: normal;
    font-weight: 400;
    src: url("./res/fonts/CentraNo2-Light.ttf");
  } */

@font-face {
  font-family: 'Centra No2';
  src: url('https://assets.understoryweather.com/fonts/CentraNo2-Light.eot');
  src:
    url('https://assets.understoryweather.com/fonts/CentraNo2-Light.eot?#iefix') format('embedded-opentype'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Light.woff') format('woff'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('https://assets.understoryweather.com/fonts/CentraNo2-Medium.eot');
  src:
    url('https://assets.understoryweather.com/fonts/CentraNo2-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Medium.woff') format('woff'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('https://assets.understoryweather.com/fonts/CentraNo2-Bold.eot');
  src:
    url('https://assets.understoryweather.com/fonts/CentraNo2-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Bold.woff') format('woff'),
    url('https://assets.understoryweather.com/fonts/CentraNo2-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* * {
  --scrollbarBG: #f2fafa;
  --thumbBG: #5f676f;
}
*::-webkit-scrollbar {
  width: 16px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 32px;
  border: 4px solid var(--scrollbarBG);
} */

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

input:focus {
  outline: transparent;
}

a {
  text-decoration: none;
}

textarea:focus {
  outline: transparent;
}

body {
  overflow-y: auto;
}

.ag-root-wrapper-body.ag-layout-normal {
  flex: 1 1 auto !important;
  height: 100% !important;
  min-height: 0 !important;
}
.ag-root-wrapper {
  border-radius: 0 !important;
}
.ag-overlay-wrapper {
  margin-top: 35px !important;
}
.details-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
}

.details-container.open {
  max-height: 1000px; /* Set a value that's greater than the actual height of the content */
  opacity: 1;
}

.details-container.closed {
  max-height: 0;
  opacity: 0;
}

.edit-icon {
  visibility: hidden;
  transition: visibility 0.3s ease; /* Add a smooth transition */
  cursor: pointer;
}

.ag-row:hover .edit-icon {
  visibility: visible;
}

.ag-theme-alpine.white {
  --ag-header-background-color: white;
}
.ag-theme-alpine.light-gray {
  --ag-header-background-color: #f8f8f8;
}

#ag-grid-table .ag-tabs-header.ag-menu-header {
  display: none;
}

.ag-theme-quartz {
  --ag-borders: none;
  --ag-row-border-width: 2px;
  --ag-row-border-bottom: 1px solid var(--Components-Input-Outlined, rgba(145, 158, 171, 0.32));
  --ag-background: 'white';
  --ag-row-height: 70px;
  --ag-header-height: 48px;
  --ag-row-text-overflow: ellipsis;
  --ag-row-white-space: nowrap;
  --ag-row-font-family: Roboto;
  --ag-header-background-color: white;
  --ag-header-foreground-color: #637381;
}
.ag-theme-quartz .ag-row {
  cursor: pointer;
}
.ag-body-vertical-scroll {
  display: none;
}
.ag-header-container {
  border-bottom: 1px solid #babfc7;
}

.r-height-1dernwh {
  height: 100%;
}

.input-container {
  position: relative;
  padding: 5px;
  color: '#637381';
}

.custom-input {
  width: 230px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  padding-left: 48px; /* Adjust padding */
  border: 1px solid #ccc;
  border-radius: 8px;
  color: '#637381';
  gap: 12;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.input-label {
  position: absolute;
  top: -9px; /* Adjust distance from top */
  left: 14px; /* Adjust distance from left */
  padding: 0 5px; /* Adjust padding */
  background-color: white; /* Match input background */
  color: ' #919EAB';
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
}
.calendar-icon {
  position: absolute;
  top: 50%;
  left: 20px; /* Adjust distance from the left */
  transform: translateY(-50%);
  /* Add cursor pointer */
}
.dropdown-menu {
  position: relative;
  display: inline-block;
  z-index: 9999;
  margin-left: 5px;
}

.menu-icon {
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}

.menu-content {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  /* background-color: white; */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 10px 20px;
  cursor: pointer;
}

.menu-content li:hover {
  background-color: #f0f0f0;
}

.triangle {
  position: absolute;
  top: -10px; /* Adjust this value to position the triangle properly */
  right: 5px; /* Adjust this value to position the triangle properly */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.triangle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
}

/* Hide scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 0;
}
/* Optional: style the track (the area behind the thumb) */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fcffff inset !important;
}

.loader-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Take up the full width of the viewport */
  height: 100%; /* Take up the full height of the viewport */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.spinner-container {
  animation: spin 1s infinite linear; /* Add spinning animation */
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.show {
  display: block;
}
.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-theme-quartz {
  min-height: 100px;
  display: flex;
  flex-direction: column;
}

.ag-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ag-body {
  overflow-y: auto;
}
.ag-header-group-cell-label {
  justify-content: center;
}

.ag-header-group-cell-with-group {
  justify-content: center;
}

.ag-cell-editable {
  background-color: lightblue;
  color: black; /* Optional: if you want the text to be white for better contrast */
}

.ag-cell-disable {
  background-color: #f0f0f0;
  color: black; /* Optional: if you want the text to be white for better contrast */
}
.yellow-background {
  background-color: lightgoldenrodyellow;
}
.red-background {
  background-color: red;
  color: white; /* Optional: adjust text color for readability */
}
.error-cell {
  border: '1px solid red';
  background-color: rgb(240, 203, 203);
}
.gray-background {
  background-color: #d3d3d3; /* Light gray */
}
.blue-background {
  background-color: rgb(28, 28, 119);
  color: white;
}
.ag-theme-quartz .ag-checkbox-input-wrapper.ag-checked::after {
  color: rgb(36, 241, 36) !important;
}

.ag-paging-panel {
  display: flex;
}

.paging-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-updated {
  flex: 1;
  text-align: left;
}

.pagination-controls {
  flex: 0;
  text-align: right;
}

@keyframes pulse-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.pulse-animation {
  animation: pulse-fade 1.5s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(40, 167, 69, 0.5);
}
